@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;900&display=swap');

$background-1: url('../assets/section-2-bg.jpg');
$background-2: url('../assets/section-3-bg.jpg');
$background-3: url('../assets/section-5-bg.jpg');

$font-color: #253551;
$font-color-2: #DFE8F6;
$font-color-3: #C6D2E8;
$font-color-4: #14284B;
$font-color-5: #FCB424;

.heading {
    font-size: 46px;
    margin: 0px;
    line-height: 1;
    padding: 50px;
    text-align: center;
}

.heading1 {
    color: $font-color;
}

.heading2 {
    color: $font-color-2;
}

.heading3 {
    color: $font-color-5;
}

* {
    font-family: 'Montserrat', sans-serif;

}

.link-children {
    color: white;
    text-decoration: underline;
}


a {
    text-decoration: none;
    color: rgb(47, 2, 248);
}

.landing-container {
    background-position: center 1%;
    background-color: black;
    display: grid;
    height: 75vh;
    background-image: url('../assets/hero-image-bg.jpg');
    background-size: cover;
    grid-template-columns: 320px;
}

.logo-heading-container {
    width: 420px;
    height: 330px;
    grid-column-start: 1;
    margin-top: 120px;
    margin-left: 10vw;
    width: 100%;
}

@media only screen and (max-width: 1050px) {
    .logo-heading-container {
        margin-left: 1vw;
    }
  }
  
  @media only screen and (max-width: 840px) {
    .user-action-container {
        margin-left: 10vw;
    }
  }

b {
    font-weight: 900;
}

.logo-heading {
    margin: 0;
    padding-top: 2px;
    text-indent: -99em;
    background-image: url('../assets/parentworks-logo.png');
    background-size: cover;
    background-repeat: no-repeat;
}

p {
    margin: 10px 0 10px;
    text-align: center;
    font-weight: 600;
}

.btn-primary {
    font-size: 15px;
    background-color: #fc5c24;
    border: 0;
    border-radius: 5px;
    letter-spacing: 2.5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    color: white;
    padding: 15px;
}

.btn-container {
    text-align: center;
    padding-top: 1rem;
}

.divider1 {
    position: absolute;
    width: 100%;
    background-image: url('../assets/section-2-divider.png');
    height: 4vh;
    top: 72%;
}

.about-section {
    height: 100%;
    background-image: $background-1;
    padding-bottom: 2rem;

}

.about-section p {
    color: $font-color;
}

.container {
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
    margin-right: auto;
    margin-left: auto;
    max-width: 1000px;
}



.heading4 {
    font-size: 22px;
    margin: 0;
    line-height: 1;
    color: $font-color-5;
    text-align: center;

}

.description-video-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.description-container {
    width: 100%;
    text-align: left;
    padding-right: 50px;
}

.description-video-container p {
    text-align: left;
    margin: 0;
    text-align: justify;
}

.video-container {
    width: 100%;
    height: 100%;
}


.link-container {
    width: 100%;
    text-align: center;
    text-decoration: underline;
}

.link-container a {
    color: white;
}

.divider2 {
    position: relative;
    width: 100%;
    background-image: url('../assets/section-3-divider.png');
    height: 4vh;
}

.divider3 {
    position: relative;
    width: 100%;
    background-image: url('../assets/section-4-divider.png');
    height: 4vh;
}

.divider4 {
    position: relative;
    width: 100%;
    background-image: url('../assets/section-5-divider.png');
    height: 4vh;
}

.program-section {
    height: 100%;
    background-image: $background-2;
    padding-bottom: 2rem;
}

.father-image {
    position: relative;
    padding-right: 2rem;
    height: 54vh;
}

.program-container {
    display: flex;
    margin-left: 5rem;
}

.father-description-container p {
    width: 571px;
    color: $font-color-2;
    margin-bottom: 12px;
    text-align: justify;
    width: 100%;
}

.father-video-section {
    display: flex;
    flex-direction: row;
}

.video-right {
    padding-left: 0.5rem;
}

.btn-secondary {
    font-size: 15px;
    background-color: #E9A724;
    border: 0;
    border-radius: 5px;
    letter-spacing: 2.5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    color: white;
    padding: 15px;
}

.program-for-section {
    height: 100%;
    background-image: $background-1;
    padding-bottom: 2rem;
}

.program-for-section p {
    color: $font-color;
}

.involved-section {
    color: $font-color-2;
    height: 100%;
    background-image: $background-2;
    padding-bottom: 2rem;
}

.btn-blue {
    background-color: #364D75;
    text-align: left;

}

.btn-container-secondary {
    text-align: left;
    padding-top: 1rem;
}

.kids-fighting {
    width: 50%;
    object-fit: cover;
}

.testimonial-container {
    display: flex;

}

.testimonial-container p {
    width: 50%;
    text-align: justify;
    padding: 1.6rem;
    font-size: 16px;
    font-style: italic;
    color: $font-color-2;
}

.contactus-section {
    height: 100%;
    background-image: url('../assets/section-3-bg.jpg');
    padding-bottom: 2rem;
    color: $font-color-2;
}

.acknowledgements-section {
    height: 100%;
    background-image: url('../assets/section-5-bg.jpg');
    padding-bottom: 2rem;
    color: $font-color-2;
}


.testimonials-socials-section {
    height: 100%;
    background-image: url('../assets/section-3-bg.jpg');
    padding-bottom: 2rem;
}

.socials-container {
    display: flex;
    flex-direction: row;
    color: white;
    font-size: 18px;
    justify-content: center;
}

.socials-container p {
    padding-left: 2rem;
    padding-right: .5rem;
}

.youtube {
    background-color: red;
    text-decoration: none;

}

.facebook {
    background-color: #3B5998;
    margin-right: 10px;
}

.twitter {
    background-color: #00ACEE;
}



.bottom-heading {
    font-size: 36px;
    color: $font-color-5;
    margin: 30px 20px;
    text-align: center;
}

.usydLogo {
    width: 15%;
}

.movemberLogo {
    width: 6.5%;
    margin-left: 7rem;
}

.footer-logo-container {
    margin: 30px 0;
}


.logo-heading-container p {
    font-weight: bold;
    color: $font-color-4;
    text-align: left;
}

.testimonial-container .testimonial-heading {
    margin: 0;
    padding: 0;
}

.research-container p, .research-container i {
    text-align: justify;
}

.footer-logo {
    background-image: $background-1;
    padding: 20px 0;
}

.usyd-logo-container h3 {
    margin: auto 20px auto 0;
    color: #14284B;
    font-weight: 600;
  }


/* Mobile view */
@media only screen and (max-width: 840px) {

    .desktop-links {
        display: none;
    }

    .desktop-links-end {
        display: none;
    }

    

    .father-image {
        max-width: 100%;
        height: auto;
        display: table;
        margin: 0 auto;
        text-align: center;
        padding: 0;

    }



    .kids-fighting {
        width: 100%;
        object-fit: cover;
    }

    .testimonial-container {
        min-width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .testimonial-container p {
        width: 100%;
        padding: 0;
    }

    .description-container {
        width: auto;
        padding: 0;
    }

    .btn-container-secondary {

        padding-bottom: 1rem;
    }

    .socials-container p {
        padding-left: .5rem;
        padding-right: .5rem;
    }

    .usydLogo {
        width: 50%;
    }

    .movemberLogo {
        width: 25%;
        margin-left: 4rem;
    }

    .father-video-section {
        flex-direction: column;
    }

    .father-video-section iframe {
        width: auto;
        height: 264px;
        margin-bottom: 10px;
    }

    .video-right {
        padding-left: 0rem;
    }


    .program-container {
        flex-direction: column;
        margin: 0;
    }

    .father-description-container p {
        width: 100%;
        font-size: 18px;
    }

    video {
        width: 100%;
        height: 100%;
    }

    .home-heading {
        font-size: 30px;
        width: auto;
        padding-left: 0.7rem;
    }

    p {
        font-size: 18px;
    }

    .landing-container {
        height: 35vh;
        grid-template-columns: 5px;
        background-position: right .1px top 3rem;
        background-repeat: no-repeat;
        background-color: white;
    }

    .logo-heading-container p {
        margin-left: 10vw;
        font-weight: bold;
        width: 90vw;
    }
   
    .logo-heading {
        margin-left: 10vw;
        padding-left: 4500%;
        background-size: 100%;
        background-repeat: no-repeat;
        margin-top: -2rem;
    }

    .btn-container {
        width: fit-content;
        margin: 0;
    }

    .divider1 {
        top: 22rem;
        position: absolute;
    }

    .description-video-container {
        flex-direction: column;
    }

    .heading1 {
        font-size: 20px;
    }

    .heading2 {
        font-size: 20px;
    }

    .heading3 {
        font-size: 20px;
    }

    b {
        font-weight: 600;
    }

    p {
        margin: 10px 0 10px;
        text-align: center;
        font-weight: 400;
    }

    .btn-container {
        width: 100%;
    }

}