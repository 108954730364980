body {
    background-image: url('../assets/section-5-bg.jpg');
}

nav {
    top: 0;
}

.email {
 color: #FCB424;
 text-align: center;
 
}

.contact {
    margin-top: 7rem !important;
    
   
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7rem;
}

.contact-details {
    width: 50%;
}

p {
    color: #E0EDF9;
    
}

label {
    color: #E0EDF9;
    margin-bottom: .5rem;
}

input {
    height: 40px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
}

.emailP {
    margin-bottom: 20px;
}

.submitBtn {
    background-color: #FC5C24;
    color: white;
    border: none;
    border-radius: 5px;
    height: 40px;
    width: 100px;
    margin: auto;
    margin-bottom: 20px;
}

.enquiry {
    min-height: 150px;
    max-height: 250px;
    border-radius: 5px;
    max-width: 100%;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 1000px) {
    .contactUs {
        width: 100%;
    }
    .heading {
        margin-top: 150px;
    }
    .contact-details {
        width: 100%;
    }
    .contact {
      margin-left: 2rem;
       
    }

  
}

@media only screen and (max-width: 600px) {
    .contactUs {
        width: 100%;
        margin: 0rem;
    }
    
    .contact-details {
        width: 150%;
        margin: 0rem;
    }
    .contact {
      width: 84%;
      margin-left: 0.8rem;
      
    }

  
}

