.part-heading {
    color: #2F3E66;
    font-size: 22px;
    margin: 30px 10px;
}

.part-resource {
    color: #FCB424;
    font-size: 18px;
    text-align: left;
    margin: 30px 10px;
    width: 100%;
    overflow-x: wrap;
}


.section-description {
    color: #E0EDF9;
    font-size: 18px;
    text-align: left;
    margin: 30px 10px;
}

.part-heading-red {
    color: #FC5C24;
    font-size: 22px;
    margin: 30px 10px;
}

.section-heading {
    margin: 0;
    padding-top: 2rem;
}

.state-resource-title {
    color: #FC5C24;
    font-size: 22px;
    text-align: left;
    margin: 30px 10px;
    text-decoration: underline;
}

.link-resource {
    width: 100%;
    word-wrap: break-word;
    display: inline-block;
}

@media only screen and (max-width: 1000px) {
 .heading {
    margin-top: 0px;
 }
}