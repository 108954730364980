html,
body {
  height: 100%;
  margin: 0;
  color: #121212;
}

.user-action-container {
  display: flex;
  position: absolute;
  flex-direction: row;
  background-color: #ff4e00;
  padding: 15px 12px 15px;
  width: 136px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  left: auto;
  top: 0;
  margin-left: 19rem;
}

.desktop-links-end {
    margin-right: 13rem;
}

nav {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 54px;
  z-index: 1;
  padding-right: 2rem !important;
}

nav a {
  padding: 15px 15px;
  font-size: 12px;
  color: #5C5E5A;
  
}

nav a:hover {
  color: #ff4e00;
}

nav span {
  font-size: 11px;
  color: white;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.usyd-logo-container {
  display: flex;
  height: 60px;
  justify-content: flex-end;
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
}

.or {
  padding-top: .95rem;
}

nav .active {
  text-decoration: underline;
  color: #ff4e00;
}

@media only screen and (max-width: 1050px) {
  .usyd-logo-container {
    height: 40px;
  }
  .usyd-logo-container h3 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 840px) {
  .user-action-container {
    right: 6rem;
  }

}