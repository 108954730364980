

@media only screen and (min-width: 841px) {
    .hamburger-menu {
        display: none;
    }
}

@media only screen and (max-width: 840px) {
    .hamburger {
        position: absolute;
        left: 5px;
        margin-top: .3rem;
    }
    .hamburger-menu {
        background-color: #364d75;
        width: 100%;
        position:absolute;
        left: 0;
    }
    .hamburger-menu ul {
        list-style-type: none;
        
    }

    .navigationOff {
        display: none;
    }

    .navigationOn {
        display: contents;
    }
    .items {
        
        margin-top: 3rem;
        padding: 1rem;
    }

    .items li {
        padding: 1rem;
        color: white;
        font-size: 16px;
        display: flex;
    }

    
    .items li:hover {
        background-color: #ff4e00;
        color: white !important;
    }

      
}
